import { height, width } from 'dom7';
import $ from 'jquery';
import 'slick-carousel';

export default function slider() {
    const slider = $('.slider__display-area');
    const img1 = $('.img-1');
    const img2 = $('.img-2');
    const img3 = $('.img-3');
    const sp = () => {
        if(navigator.userAgent.match(/iPhone|Android. + Mobile/)) {
            return true;
        } else {
            return false;
        }
    }

    // デバイスの画面が1120以下の時にセットアップ
    function setDeviceHeight() {
        const sliderWidth = $('.slider__display-area').width();
        const deviceWidth = $(window).width();
        const spSliderHeight = sliderWidth * 0.54;

        if(deviceWidth < 1120 ) {
            slider.height(spSliderHeight);
            img1.height(spSliderHeight)
            img2.height(spSliderHeight)
            img3.height(spSliderHeight)
        } else {
            slider.removeAttr('style');
            img1.height(slider.height())
            img2.height(slider.height())
            img3.height(slider.height())
        }
    }

    function slick() {
        slider.slick({
            arrows: false,
            speed: sp ? 1500 : 1000,
            autoplay: true,
            autoplaySpeed: sp ? 4000 : 8000,
            swipe: false
        });
    }

    function setActive() {
        if(img1.hasClass('active')) {
            img1.removeClass('active');
            img2.addClass('active');
        } else if(img2.hasClass('active')) {
            img2.removeClass('active');
            img3.addClass('active');
        } else if(img3.hasClass('active')) {
            img3.removeClass('active');
            img1.addClass('active');
        }
    }

    setDeviceHeight();
    window.addEventListener('resize', setDeviceHeight);
    slick()
    slider.on('afterChange', () => {
        setActive()
    });
}