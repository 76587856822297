import SmoothScroll from 'smooth-scroll'
import Contact from './contact/contact'
import Slider from './slider';

new SmoothScroll('a[href*="#"]', {
	speed: 900,
	speedAsDuration: true,
	offset: 0
});

new Contact()
Slider()

// ポップアップのクローズボタンの処理
const popupClose = document.querySelector('.popup--close');

popupClose.addEventListener('click', () => {
	const target = document.getElementById('popup');
	target.classList.add('none');
});

// ポップアップの表示を管理
function popupControl() {
	const section3 = document.getElementById('section-3');
	const taikenkaiSection = document.getElementById('taikenkai');
	const popup = document.getElementById('popup');
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.1
	}

	// section4に入ったタイミングでポップアップを表示
	const observerAppear = new IntersectionObserver((IntersectionObserver) => {
		if(IntersectionObserver[0].isIntersecting) {
			popup.style.opacity = 1
		}
	}, options);

	// taikenkaiに入ったタイミングでポップアップを消す
	const observerDisappear = new IntersectionObserver((IntersectionObserver) => {
		if(IntersectionObserver[0].isIntersecting) {
			const target = document.getElementById('popup');
			target.classList.add('none');
		}
	}, options);

	// スマホ時の操作（メディアクエリで判定）
	window.onload = () => {
		if(window.matchMedia('(max-width: 649px)').matches) {
			// ポップアップのコンテンツ領域の高さを取得
			const popupContentHeight = document.querySelector('.popup--main').clientHeight;
			// 初期表示位置をポップアップコンテンツ分下げる
			popup.style.transform = `translateY(${popupContentHeight}px)`
			// ポップアップのヘッダーを取得
			const popupHeading = document.querySelector('.popup--heading');
			// ポップアップのヘッダーにflag属性を追加
			popupHeading.setAttribute('flag', '0');
			// 表示と非表示を調整
			popupHeading.addEventListener('click', () => {
				if(popupHeading.getAttribute('flag') == '0') {
					popup.style.transform = `translateY(0)`;
					popupHeading.setAttribute('flag', '1');
				} else {
					popup.style.transform = `translateY(${popupContentHeight}px)`;
					popupHeading.setAttribute('flag', '0');
				}
			});

			// スワイプで表示と非表示の切り替え
			const popupMainImg = document.querySelector('.popup--main--img');
			let startY;
			let moveY;

			popupMainImg.addEventListener('touchstart', (e) => {
				e.preventDefault();
				startY = e.touches[0].pageY;
			});

			popupMainImg.addEventListener('touchmove', (e) => {
				// e.preventDefault();
				moveY = e.changedTouches[0].pageY;
			});

			popupMainImg.addEventListener('touchend', (e) => {
				if(moveY - startY > 60) {
					popup.style.transform = `translateY(${popupContentHeight}px)`
					popupHeading.setAttribute('flag', '0');
				}
			});
		}
	}

	// 体験会の詳細ボタンをクリックしたらポップアップを非表示にする
	const popupButton = document.querySelector('.popup--main--button');
	popupButton.addEventListener('click', () => {
		const target = document.getElementById('popup');
		target.classList.add('none');
	});

	observerAppear.observe(section3);
	observerDisappear.observe(taikenkaiSection);
}

popupControl();