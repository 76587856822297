
/**
 * 
 * @param formValues 
 * @param validObject 
 * @returns hasFormError
 */
function validateForm(formValues, validObject) {
    const hasErrors = []
    for (const [key, value] of Object.entries(validObject)) {
        const inputValue = formValues[key]

        if (value.required?.isrequired) {
            if (!inputValue) {
                hasErrors.push(key)
                value.isValid = false
                value.errorMessage = value.required.errorMessage
            } else {
                hasErrors.filter(t => t !== key)
                value.isValid = true
                value.errorMessage = ''
            }
        }
        if (inputValue) {
            if (value.maxLength) {
                if (inputValue.length < value.maxLength.max) {
                    hasErrors.push(key)
                    value.isValid = false
                    value.errorMessage = value.maxLength.errorMessage
                } else {
                    hasErrors.filter(t => t !== key)
                    value.isValid = true
                    value.errorMessage = ''
                }
            }
            if (value.match) {
                if (!value.match.regex.test(inputValue)) {
                    hasErrors.push(key)
                    value.isValid = false
                    value.errorMessage = value.match.errorMessage
                } else {
                    hasErrors.filter(t => t !== key)
                    value.isValid = true
                    value.errorMessage = ''
                }
            }
            if (value.checkSame) {
                if (formValues[value.checkSame.field] !== inputValue) {
                    hasErrors.push(key)
                    value.isValid = false
                    value.errorMessage = value.checkSame.errorMessage
                } else {
                    hasErrors.filter(t => t !== key)
                    value.isValid = true
                    value.errorMessage = ''
                }
            }

            if(value.number){
                if(typeof inputValue !== 'number'){
                    hasErrors.push(key)
                    value.isValid = false
                    value.errorMessage = value.number.errorMessage
                    
                }else{
                    hasErrors.filter(t => t !== key)
                    value.isValid = true
                    value.errorMessage = ''

                    if(value.number.max && value.number.max.length){
                        if(inputValue > value.number.max.length){
                            hasErrors.push(key)
                            value.isValid = false
                            value.errorMessage = value.number.max.errorMessage
                        }else{
                            hasErrors.filter(t => t !== key)
                            value.isValid = true
                            value.errorMessage = ''
                        }

                    }
                    if(value.number.min && value.number.min.length){
                        if(inputValue < value.number.min.length){
                            hasErrors.push(key)
                            value.isValid = false
                            value.errorMessage = value.number.min.errorMessage
                        }else{
                            hasErrors.filter(t => t !== key)
                            value.isValid = true
                            value.errorMessage = ''
                        }
                    }
                }
            }

        }
    }
    return hasErrors.length > 0 ? true : false
}


export default validateForm;