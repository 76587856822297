class ConfirmView {

    constructor(){
        this.parent = document.querySelector('form[name="form-step-2"]')
    }
    displayThanksContent(){
        const formParent = document.querySelector('.form-step-2')
        const html = `
        <div class="section-contact__main thanks-form__main">
            <p class="form-step-2--title">お問い合わせ完了</p>
            <p class="thanks-form--title">お問い合わせいただき、ありがとうございます。</p>
            <p class="thanks-form--p">この度は、お問い合わせいただき誠にありがとうございます。お問い合わせの内容を確認次第、弊社スタッフから折返しご連絡を差し上げます。弊社からのご連絡は通常1営業日以内に行っております。土日祝日などを挟む場合は、ご連絡までお時間をいただくことがございますので、ご了承ください。<br>
            お急ぎの方は、電話から直接お問い合わせいただくことも可能です。</p>
            <div class="thanks-form__phone">
                <p class="thanks-form__phone--f">電話番号</p>
                <p class="section-contact__main--tel">
                    <img src="./assets/images/icon-phone.png" alt="tel">
                    <span class="tel none-sp">022-263-1645</span>
                    <a class="none-pc tel" href="tel:022-263-1645">022-263-1645</a>
                    <span class="time">受付時間：平日10:00~18:00</span>
                </p>
            </div>
        </div>
        `
        formParent.innerHTML = html

    }

    setView(values){
       const confirmForm = document.querySelector('.confirm-form')
       confirmForm && this.parent.removeChild(confirmForm)
        const html = 
        `
        <input type="hidden" value="${values.name}" name="name" id="name">
        <input type="hidden" value="${values.email}" name="email" id="email">
        <input type="hidden" value="${values.salonName}" name="salonName" id="salonName">
        <input type="hidden" value="${values.phone}" name="phone" id="phone">
        <input type="hidden" value="${values.content}" name="content" id="content">
        <div class="confirm-form">
        <div class="confirm-form--item">
            <p>お名前</p>
            <p>${values.name} 様</p>
        </div>
        <div class="confirm-form--item">
            <p>メールアドレス</p>
            <p>${values.email}</p>
        </div>
        <div class="confirm-form--item">
            <p>電話番号</p>
            <p>${values.phone}</p>
        </div>
        <div class="confirm-form--item">
            <p>サロン名</p>
            <p>${values.salonName}</p>
        </div>
     </div>`
     this.parent.insertAdjacentHTML('afterbegin',html)
    }

}

export default new ConfirmView()
