import $ from 'jquery'
import validateForm from '../validation'
import ConfirmView from './confirmView'

class Contact {
    constructor() {
        this.returnBtn = document.getElementById('return-form-1')
        this.form1 = document.querySelector('form[name="form-step-1"]')
        this.form2 = document.querySelector('form[name="form-step-2"]')
        this.form1Wrapper = document.querySelector('.form-step-1')
        this.form2Wrapper = document.querySelector('.form-step-2')
        this.validation = {
            name: {
                isValid: null,
                errorMessage: '',
                required: {
                    isrequired: true,
                    errorMessage: 'お名前をご入力ください'
                }
            },
            email: {
                isValid: null,
                errorMessage: '',
                required: {
                    isrequired: true,
                    errorMessage: 'メールアドレスをご入力ください'
                },
                match: {
                    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    errorMessage: '正しいメールを入力ください',
                }
            },
            phone: {
                isValid: null,
                errorMessage: '',
                required: {
                    isrequired: true,
                    errorMessage: '電話番号をご入力ください'
                }
            },
            salonName: {
                isValid: null,
                errorMessage: '',
                required: {
                    isrequired: true,
                    errorMessage: 'サロン名をご入力ください'
                }
            }
        }
        this.values = {
            name: '',
            email: '',
            salonName: '',
            phone: '',
        }

        this.form1.addEventListener('submit', this.handleSubmitForm1.bind(this))
        this.form2.addEventListener('submit', this.handleSendRequest.bind(this))
        this.returnBtn.addEventListener('click', this.goToStep1.bind(this))
    }


    getFormValues() {
        this.values.name = this.form1['name'].value
        this.values.email = this.form1['email'].value
        this.values.salonName = this.form1['salonName'].value
        this.values.phone = this.form1['phone'].value
    }

    goToStep2() {
        this.form1Wrapper.classList.add('out')
        this.form2Wrapper.classList.add('in')
    }
    goToStep1() {
        this.form1Wrapper.classList.remove('out')
        this.form2Wrapper.classList.remove('in')
    }
    displayFormErrors() {
        const errors = Object.keys(this.validation)
        for (let error of errors) {
            const er = document.querySelector(`#error-${error}`)
            er.textContent = this.validation[error].errorMessage
        }
    }

    handleSubmitForm1(e) {
        e.preventDefault()
        this.getFormValues()

        const hasFormError = validateForm(this.values, this.validation)
        this.displayFormErrors()
        if (!hasFormError) {
            ConfirmView.setView(this.values)
            this.goToStep2()
        }
    }


    handleSendRequest(e) {
        e.preventDefault();
        this.disableButton()
        $.post('./app/contact.php', this.values).done((data) => {
            ConfirmView.displayThanksContent()
        });
    }

    disableButton(){
        const btnList = this.form2.querySelectorAll('button')
        btnList.forEach(btn=>{
            btn.disabled = true
        })
    }
}

export default Contact












